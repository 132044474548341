import { Placemark, Polygon, Polyline } from '@pbe/react-yandex-maps'
import React, { useEffect, useMemo, useState } from 'react'
import { colorMap } from './MapComponent'
import getArrayDepth from '../UI/functions/getArrayDepth'
import mapColors from '../UI/mapColors'

const MapSwitchGeoObject = ({ type = '', options = {}, properties = {}, geometry = [], onClick = () => { }, isActive = false, isEdit = false, isNewDrawing = false, onChange = () => { }, children }) => {
    const [ref, setRef] = useState(null)

    const fixedGeometry = useMemo(() => {

        if (isNewDrawing)
            return geometry
        if (geometry?.length === 0)
            return null
        if (type === 'polygon') {
            const polygonGeometry = getArrayDepth(geometry) > 2 ? geometry : [geometry] // возвращает правильную вложенность для полигона
            if (polygonGeometry[0]?.[0]?.length > 0) {
                if (polygonGeometry[0][0] > 100) {
                    console.log('неправильные координаты')
                    return null
                }
                return polygonGeometry
            }
            return null
        }
        return geometry
    }, [])

    const isOptions = useMemo(() => {
        const newopt = { ...options }
        if (isEdit) {
            newopt.strokeStyle = "dash"
        }
        return newopt
    }, [isEdit])

    useEffect(() => {
        if (ref) {
            if (isEdit) {
                if (isNewDrawing) {
                    ref.editor.startDrawing()
                } else {
                    ref.editor.startEditing()
                }
            } else {
                if (isNewDrawing) {
                    ref.editor.stopDrawing()
                } else {
                    ref.editor.stopEditing()
                }
            }
        }
    }, [ref, isEdit])

    useEffect(() => {
        if (ref && isActive && type === "polygon") {
            console.log('activeLandplot ref',ref )
            const map = ref.getMap()
            const bounds = ref.geometry.getBounds()
            map.setBounds(bounds)
        }
    }, [ref]);

    const handleChange = (event) => {
        // Получаем новые координаты после редактирования
        const newCoordinates = event.get('target').geometry.getCoordinates();
        onChange(newCoordinates)
    };
    if (fixedGeometry) {
        switch (type) {
            case 'polygon':
                return <Polygon
                    geometry={isNewDrawing ? geometry : fixedGeometry}
                    instanceRef={ref => ref && setRef(ref)}
                    options={isOptions}
                    properties={properties}
                    onClick={onClick}
                    onGeometryChange={isEdit ? handleChange : () => { }}
                >
                    {children}
                </Polygon>
            case 'polyline':
                return <Polyline
                    geometry={fixedGeometry}
                    instanceRef={ref => ref && setRef(ref)}
                    options={isOptions}
                    properties={properties}
                    onClick={onClick}
                    onGeometryChange={isEdit ? handleChange : () => { }}
                >{children}</Polyline>
            case 'placemark':
                return <Placemark geometry={fixedGeometry}
                    instanceRef={ref => ref && setRef(ref)}
                    onClick={onClick}
                    options={options}
                    properties={properties}
                    onGeometryChange={isEdit ? handleChange : () => { }}
                />
            default:
                return <>неизвестный тип: {type}</>
        }
    }
    return <></>

}

export default MapSwitchGeoObject