export async function getDebtMicrodistricts(microdistricts_ids) {
    try {
        const queryParams = new URLSearchParams()
        microdistricts_ids.forEach(id => {
            queryParams.append('microdistrict', id)
        })
        const url = process.env.REACT_APP_BASE_URL + `microdistrict/get_debt_microdistricts/?${queryParams.toString()}`
        const res = await fetch(url)
        return res.ok ? await res.blob() : null
    } catch (e) {
        return null
    }
}