import React, {useEffect, useRef, useState} from "react";
import Buttons from "../../../component/Buttons/Buttons";
import {DebtButtonStyle} from "../style";
import FormInput from "../../../component/FormInput/FormInput";
import {getDebtMicrodistricts} from "../api";

export function DebtButton({microDistrictOptions}) {
    const modalWindowWrapperRef = useRef(null);
    const [isShowModalWindow, setIsShowModalWindow] = useState(false)

    useEffect(() => {
        const onMouseDown = (e) => {
            const target = e.target
            if (!modalWindowWrapperRef.current?.contains(target)) {
                setIsShowModalWindow(false)
            }
        }

        window.addEventListener("mousedown", onMouseDown);
        return () => {
            window.removeEventListener("mousedown", onMouseDown);
        }
    }, [])
    const [isLoading, setIsLoading] = useState(false)
    const chosenOptions = useRef([])
    const onSubmit = async () => {
        const values = chosenOptions.current
        if (values.length > 0) {
            setIsLoading(true)
            const response = await getDebtMicrodistricts(values)
            if (response != null) {
                try {
                    const blobUrl = window.URL.createObjectURL(response)

                    const link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = blobUrl
                    const name = microDistrictOptions.value.choices.filter(item => values.includes(item.value)).map(item => item.display_name)
                    link.download = `${name.join(',')}.xlsx`;

                    document.body.appendChild(link)
                    link.click()

                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(blobUrl)
                } catch (error) {
                    console.error('Download failed:', error)
                }
            }
            setIsLoading(false)
        }
    }
    return (
        <div className={DebtButtonStyle.DebtButton} ref={modalWindowWrapperRef}>
            <Buttons
                text={'Задолженность ТБО'}
                className={`filterButton ${DebtButtonStyle.button}`}
                onClick={() => setIsShowModalWindow(prevState => !prevState)}
            />
            {isShowModalWindow &&
                <div className={DebtButtonStyle.modalWindow}>
                    <FormInput
                        subInput={microDistrictOptions.value.label}
                        // value={dataLandploatFilter[item.key]}
                        options={microDistrictOptions.value.choices}
                        multiple={true}
                        onChange={(e) => {
                            // console.log(e)
                            chosenOptions.current = e
                        }}
                        type={microDistrictOptions.value.type}
                    />
                    <Buttons
                        text={isLoading ? 'Загрузка...' : 'Скачать'}
                        className={`filterButton`}
                        onClick={onSubmit}
                    />
                </div>
            }
        </div>
    )
}