import React, {useEffect, useMemo, useState} from 'react';
import Header from '../../component/Header/Header';
import icons from '../../assets/icons/icons';
import Tables from '../../component/Tables/Tables';
import {fieldToArray} from '../../component/UI/functions/functions';
import Buttons from '../../component/Buttons/Buttons';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '../../component/Modal/Modal';
import FormInput from '../../component/FormInput/FormInput';
import {setLoading} from '../../store/loadingReducer';
import Loader from '../../component/Loader/Loader';
import LandPlotApiRequest from '../../api/Landplot/LandPlot';
import ContragentApiRequest from '../../api/Contragent/Contragent';
import {cleardataLandploatFilter, setDataLandploatFilterPressItem} from '../../store/filterLandploatDataReduces';
import {DebtButton} from "./ui";

const ControlConstructTablePage = () => {
    const [isList, setIsList] = useState()
    const [isFilterOption, setIsFilterOption] = useState()
    const [isMicrodistrictsOptions, setIsMicrodistrictsOptions] = useState()
    const [isMicrodistrictsOptionsCreate, setIsMicrodistrictsOptionsCreate] = useState()
    const [isUpdate, setIsUpdate] = useState(false)
    const [isFilter, setIsFilter] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [isData, setIsData] = useState()
    const [isModal, setIsModal] = useState(false)
    const [isDishardLandploat, setIsDishardLandploat] = useState({});
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const dataLandploatFilter = useSelector((state) => state.data_landploatFilter);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading);

    const contragentApi = new ContragentApiRequest();
    const [contragentData, setContragentData] = useState([])
    const [isNextUsers, setIsNextUsers] = useState('')

    const next = isData?.next?.slice(isData.next.indexOf('?') + 1);
    const landPlot = new LandPlotApiRequest();

    useEffect(() => {
        dispatch(setLoading(true));

        landPlot.constructionControlList().then((resp) => {
            if (resp.success) {
                setIsList(fieldToArray(resp?.data?.actions['construction-control-list']));
                setIsMicrodistrictsOptions(resp?.data?.actions.discharge);
                setIsMicrodistrictsOptionsCreate(resp?.data?.actions.create);
                setIsFilterOption(resp?.data?.actions?.['filter-construction-control']);

                setIsDishardLandploat((prevIsDishardLandploat) => ({
                    ...prevIsDishardLandploat,
                    ['microdistrict_id']: 1
                }))


                // const urlParams = `&status=4`;

                const searchParams = new URLSearchParams(window.location.search);

                // Извлечь значения limit и offset из строки запроса
                const offset = searchParams.get('offset');

                const queryString = Object.keys(dataLandploatFilter)
                    .map((key) => `${key}=${dataLandploatFilter[key]}`)
                    .join('&');
                landPlot.getConstructionControlList({urlParams: `?limit=${Number(offset) + 25}&${queryString}`}).then(resp => {
                    if (resp.success) {
                        // if (Object.keys(dataLandploatFilter).length !== 0) {
                        //     // `dataLandploatFilter` является пустым объектом
                        //     filterItems();
                        //   } else {
                        //     clearFilter();
                        //   }
                        setIsData(resp.data);
                        dispatch(setLoading(false));
                        setIsLoading(false)
                    } else {
                        return;
                    }
                });

            } else {
                return;
            }
        });


    }, [isUpdate]);


    const loadMoreItems = () => {
        setIsLoadingButton(false)
        // const newOffseta = isData.next.split('?')[1];
        const offset = new URLSearchParams(isData.next).get('offset');

        landPlot.getConstructionControlList(Object.keys(dataLandploatFilter).length !== 0 ? {urlParams: `?${next}`} : {urlParams: `?offset=${offset}`}).then((resp) => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    results: [...prevData.results, ...resp.data.results],
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));
                setIsLoadingButton(false)
                // Сохранить данные пагинации в строке браузера
                const newUrl = window.location.pathname + `?offset=${offset}`;
                window.history.pushState({path: newUrl}, '', newUrl);
            }
        });
    };


    useEffect(() => {

        next != null ? setIsLoadingButton(true) : setIsLoadingButton(false)
    }, [isData])

    const filterItems = () => {

        dispatch(setLoading(true));
        const queryParams = fieldToArray(dataLandploatFilter).map(item => `${item.key}=${item.value}`).join('&');
        const urlParams = `?${queryParams}`;
        landPlot.getConstructionControlList({urlParams}).then(resp => {
            if (resp.success) {
                setIsData((prevData) => ({
                    ...prevData,
                    results: resp.data.results,
                    next: resp.data.next,
                    count: resp.data.count,
                    previous: resp.data.previous
                }));
                dispatch(setLoading(false));
                setIsFilter(false);
            }
        });
    };

    const shouldShowLoadMoreButton = () => {
        return isData?.results?.length >= 0 && isData?.results?.length < isData?.count;
    };

    const clearFilter = () => {
        dispatch(cleardataLandploatFilter())
        setIsUpdate(!isUpdate)
        setIsFilter(false)
    }

    const onSearchContragent = (e) => {
        contragentApi.searchContragent(e).then((resp) => {
            if (resp.success) {
                setContragentData(resp.data.results.map((item) => ({
                    display_name: item.org_name !== null ? item.org_name : item.fio,
                    value: item.id
                })));
                setIsNextUsers(resp.data.next);
            }
        })
    };

    const loadMoreContragent = () => {
        if (isNextUsers !== null && !loading) {
            setLoading(true);
            contragentApi.NextContragent(isNextUsers).then((resp) => {
                if (resp.success) {

                    setContragentData((prevContragent) => [
                        ...prevContragent,
                        ...resp.data.results.map((item) => ({
                            display_name: item.org_name !== null ? item.org_name : item.fio,
                            value: item.id
                        }))
                    ]);
                    setIsNextUsers(resp.data.next);
                    setLoading(false);

                }
            })
        }
    };

    useEffect(() => {
        localStorage.setItem('filterLanploat', JSON.stringify(dataLandploatFilter))
    }, [dataLandploatFilter])

    useEffect(() => {
        loadMoreContragent();
    }, []);

    const fieldArray = useMemo(() => {
        return fieldToArray(isFilterOption)
    }, [isFilterOption])

    return (
        <>
            {
                loading ?
                    <Loader/>
                    :
                    <div>
                        {
                            isFilter &&
                            <Modal
                                content={
                                    <div className='containerModal filter'>
                                        <h1>Фильтры</h1>
                                        {
                                            fieldArray.map((item) => {
                                                if (item.key === 'status') {
                                                    return null;
                                                }
                                                return (
                                                    <FormInput
                                                        subInput={item.value.label}
                                                        value={dataLandploatFilter[item.key]}
                                                        options={item?.value?.choices
                                                            ? item.value.choices
                                                            : item.key === 'contragent'
                                                                ? contragentData.length > 0 ? contragentData : [{}]
                                                                : ''}
                                                        onChange={(e) => {
                                                            dispatch(setDataLandploatFilterPressItem([item.key, e]));
                                                        }}
                                                        onScroll={loadMoreContragent}
                                                        onSearch={(e) => onSearchContragent(e)}
                                                        type={item.value.type}
                                                    />
                                                )
                                            })
                                        }


                                        <div className='modalButtonContainer'>

                                            <Buttons text={'Принять'} onClick={() => {
                                                filterItems()
                                            }}/>
                                            <Buttons text={'Отменить'} className={'greyButton'} onClick={() => {
                                                clearFilter()
                                            }}/>
                                        </div>
                                    </div>
                                }
                                onClose={() => setIsFilter(false)}
                            />
                        }
                        <Header
                            name={'Контроль строительства'}
                            icon={icons.map}
                            buttonContent={
                                <div className='regionContainer'>
                                    <DebtButton
                                        microDistrictOptions={fieldArray.find(item => item.key === "micro_district")}/>
                                    <Buttons
                                        circle={fieldToArray(dataLandploatFilter).length > 0 && fieldToArray(dataLandploatFilter).length}
                                        ico={icons.Filter}
                                        text={'Фильтры'}
                                        className={'filterButton'}
                                        onClick={() => setIsFilter(true)}
                                    />
                                </div>
                            }
                            lenght={isData?.count}
                        />
                        {
                            (isData?.results?.length >= 0) &&
                            <Tables isData={isData?.results} isList={isList} type={'control'}
                                    isLoadingButton={isLoadingButton} loading={loading} onLoad={() => {
                                shouldShowLoadMoreButton() && (loadMoreItems())
                            }}/>
                        }

                    </div>
            }
        </>

    )
}

export default ControlConstructTablePage;